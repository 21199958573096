import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';
import { connect } from 'react-redux';

import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';
import featureSwitch from '../services/featureSwitch';
import routesMap from '../Routes';

import OrderContainer from '../pagesComponents/common/OrderContainer';
import { Icon } from '../components/Home/homeStyledComponents';
import { mobileThresholdPixels, colors, Wrapper, Button, Block, Header2, Title2, Logo, TextLink }
  from '../components/styledComponents';
import android from '../assets/android.svg';
import iOS from '../assets/iOS.svg';

const P = styled.p`
  color: ${colors.navy};
  text-align: justify;
  padding: 20px;
  font-weight: 300;
  margin: 20px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px 0px;
    text-align: left;
    font-size: 12px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Row = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

function reinitOrder(trackingOriginElement, dispatch) {
  trackEvent('click', trackingOriginElement);
  dispatch({ type: 'ORDER.INIT' });
}

const renderOnTheSpotEvent = () => (
  <div>
    <P>
      Vos vêtements sont entre de bonnes mains avec notre tilliste Virginie.
    </P>
    <P>
      Sézane reviendra vers vous pour programmer la livraison de vos vêtements.
    </P>
    <P>
      Toute l’équipe Tilli vous remercie !
    </P>
  </div>
);

const renderDefaultText = order => (
  <div>
    <P>
      {featureSwitch('order_thirdPersonLabels') ?
        <Bold>{'Tilli prend maintenant le relais. '}</Bold> : '' }
      Nous faisons maintenant le maximum pour trouver le couturier qui correspondra le mieux à vos besoins.
    </P>

    <P>
      {featureSwitch('order_thirdPersonLabels') ?
        'D‘autres vêtements à ajouter ? C‘est possible, il suffit de demander au couturier pendant le rdv.' :
        'D‘autres vêtements à ajouter ? C‘est possible, il suffit de demander à votre couturier pendant le rdv.'
      }
    </P>

    {order && !order.cardToken && !featureSwitch('order_thirdPersonLabels') &&
      <Row>
        <Link
          to={`${routesMap.Payment.url}/${order._id}/${order.customer._id}`} // eslint-disable-line
          style={{ flex: 1, textAlign: 'center' }}
        >
          <Button noMargin>{'enregistrer ma carte'}</Button>
        </Link>
      </Row>
    }

    <P>
      {featureSwitch('order_thirdPersonLabels') ?
        'Pour suivre sa commande ou parrainer ses amis, votre client peut se connecter sur notre application :' :
        'Pour suivre votre commande ou parrainer vos amis, vous pouvez vous connecter sur notre application :'
      }
    </P>
  </div>
);

const renderDefaultTitle = () => (
  featureSwitch('order_thirdPersonLabels') ?
    'la commande de votre client a bien été enregistrée' :
    'votre commande a bien été enregistrée'
);

class Success extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { dispatch, order } = this.props;
    return (
      <OrderContainer>
        <Wrapper>
          <Block>
            <Header2>
              <Link
                to={'/'} style={{ flex: 1, textAlign: 'center' }}
                onClick={() => reinitOrder('home_success', dispatch)}
              >
                <Logo src={LogoImg} />
              </Link>
              <Title2>
                {
                  featureSwitch('success_onTheSpotEvent') ?
                    'Merci !' :
                    renderDefaultTitle()
                }
              </Title2>
            </Header2>

            {
              featureSwitch('success_onTheSpotEvent') ?
                renderOnTheSpotEvent() :
                renderDefaultText(order)
            }

            <Row>
              <a
                target="_blank" rel="noreferrer noopener"
                href="https://play.google.com/store/apps/details?id=fr.tilli.app"
                onClick={() => trackEvent('click', 'store_android_success')}
              >
                <Icon src={android} alt="application Android" />
              </a>
              <a
                target="_blank" rel="noreferrer noopener"
                href="https://itunes.apple.com/us/app/tilli/id1280578121"
                onClick={() => trackEvent('click', 'store_iOS_success')}
              >
                <Icon src={iOS} alt="application iPhone" />
              </a>
            </Row>


            <Link
              to={'/'}
              style={{ flex: 1, textAlign: 'center' }}
              onClick={() => reinitOrder('home_success', dispatch)}
            >
              <TextLink>retour à l‘accueil</TextLink>
            </Link>
          </Block>
        </Wrapper>
      </OrderContainer>
    );
  }
}

Success.propTypes = {
  dispatch: PropTypes.func.isRequired,
  order: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  clothes: state.order.clothes,
  order: state.order.submittedOrder,
});

const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Success);
