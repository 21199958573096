import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../layouts/index';

import Success from '../../../components/Successv1';

const SuccessContainer = ({ location }) => (
  <Layout slug="Success">
    <Success
      showCard={location.state && location.state.showCard}
      order={location.state && location.state.order}
    />
  </Layout>
);

SuccessContainer.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default SuccessContainer;
